import React, { useEffect } from "react";
import styled from "styled-components";
import { ScrollLocky } from "react-scroll-locky";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";
import {
  mainPurple,
  mainWhite,
  mainOrange,
  mainCyan,
  lightPurple,
  screen,
} from "../../components/variables";
import {
  IconCircleWhiteMd,
  IconCircleWhiteLg,
  IconCircleLightPurpleMd,
  IconCircleLightPurpleLg,
} from "../../components/circles";
import IconClose from "../../images/svg/icon-close.svg";

// motion framer animation props value
const container = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  show: {
    y: 0,
    opacity: 1,
  },
  hidden: {
    y: 75,
    opacity: 0,
  },
};

const Wrapper = styled(motion.div)`
  background: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    background: ${(props) => (props.isOddItem ? mainOrange : mainPurple)};
    border-radius: 13px;
    color: ${mainWhite};
    max-width: 1250px;
    width: 100%;
    height: calc(100% - 44px);
    padding: 32px 22px;
    position: relative;
    margin: 0 22px;
    overflow-y: scroll;
    @media ${screen.xxsmall} {
      height: auto;
      max-height: calc(100vh - 100px);
      padding: 42px;
      margin: 0 50px;
    }
    @media ${screen.mediumtall} {
      overflow: visible;
      max-height: calc(100vh - 120px);
      max-width: 1300px;
      padding: 75px;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: ${mainCyan};
    }

    .flex {
      display: flex;
      flex-direction: column;
      margin: 0;
      @media ${screen.xsmall} {
        flex-direction: row;
      }

      .col {
        &--text {
          @media ${screen.xsmall} {
            width: 52%;
          }

          .name {
            font-weight: 900;
            line-height: 1.19;
            font-size: 1.25rem;
            @media ${screen.xxsmall} {
              font-size: 2.8rem;
              margin: 21px 0 0 0;
            }
            @media ${screen.small} {
              font-size: 3rem;
              margin: 0;
            }
          }

          .role {
            font-weight: 400;
            line-height: 1.4;
            margin: 11px 0 22px 0;
            display: block;
            font-size: 1.2rem;
            @media ${screen.xxsmall} {
              font-size: 1.52rem;
              font-weight: 700;
            }
            @media ${screen.small} {
              font-size: 1.55rem;
            }
          }

          .description {
            p {
              margin: 0 0 21px 0;
              font-size: 1.02rem;
              @media ${screen.small} {
                font-size: 1.06rem;
              }
              &:last-child {
                margin: 0;
              }
            }

            a {
              color: ${mainWhite};
              text-decoration: underline;
              @media ${screen.withCursor} {
                &:hover {
                  color: ${lightPurple};
                }
              }
            }
          }
        }

        &--img {
          @media ${screen.xsmall} {
            width: 48%;
            padding: 0 0 0 70px;
          }

          .subtext {
            margin: 42px 0 32px 0;
            @media ${screen.xsmall} {
              margin: 60px 0 0 30px;
            }

            h6 {
              font-size: 1.1rem;
              letter-spacing: 2px;
              font-weight: 900;
            }

            p {
              margin: 16px 0 0 0;
              white-space: pre-wrap;
              line-height: 2;
              font-size: 1.02rem;
              @media ${screen.xxsmall} {
                margin: 22px 0 0 0;
              }
              @media ${screen.small} {
                font-size: 1.06rem;
              }
            }
          }
        }
      }
    }

    .img-wrapper {
      position: relative;
      max-width: 170px;
      width: 100%;
      margin: 0 0 12px auto;
      display: none;
      @media ${screen.xsmall} {
        display: flex;
        max-width: 380px;
        margin: 0;
      }
      @media ${screen.mediumtall} {
        max-width: 440px;
        margin: -122px 0 0 0;
      }

      .img-subwrapper {
        width: 100%;

        img {
          border-radius: 50%;
        }
      }

      &--with-orange-bg {
        .icon-circle {
          display: block;
          @media ${screen.xxsmall} {
            display: none;
          }
          @media ${screen.small} {
            display: block;
          }

          &--white {
            width: 27px;
            top: 31px;
            left: -6px;
            @media ${screen.xxsmall} {
              width: 50px;
              left: -24px;
              top: 180px;
            }
          }
          &--light-purple-md {
            width: 18px;
            right: 10px;
            bottom: 21px;
            @media ${screen.xxsmall} {
              width: 80px;
              bottom: 36px;
              right: 5px;
            }
          }
          &--light-purple-lg {
            width: 42px;
            top: 1px;
            left: 12px;
            @media ${screen.xxsmall} {
              width: 106px;
              top: 70px;
              left: -26px;
            }
          }
        }
      }

      &--with-purple-bg {
        .icon-circle {
          display: block;
          @media ${screen.xxsmall} {
            display: none;
          }
          @media ${screen.small} {
            display: block;
          }

          &--white-md {
            width: 18px;
            right: -1px;
            bottom: 40px;
            @media ${screen.xxsmall} {
              width: 50px;
              right: -1px;
              bottom: 97px;
            }
          }

          &--white-lg {
            width: 27px;
            left: 0;
            top: 19px;
            @media ${screen.xsmall} {
              width: 80px;
              left: -26px;
              top: 95px;
            }
          }

          &--light-purple {
            width: 42px;
            right: 15px;
            bottom: -12px;
            @media ${screen.xsmall} {
              width: 106px;
              right: 30px;
              bottom: -15px;
            }
          }
        }
      }

      &--mobile-only {
        display: block;
        max-width: 170px;
        @media ${screen.xxsmall} {
          max-width: 320px;
          margin: 0;
        }
        @media ${screen.xsmall} {
          display: none;
        }
      }
    }

    .icon-close {
      position: fixed;
      z-index: 3;
      background: none;
      cursor: pointer;
      top: 40px;
      right: 40px;
      @media ${screen.xxsmall} {
        top: 32px;
        right: 32px;
        position: absolute;
      }

      span {
        display: block;
      }

      svg {
        width: 18px;
        height: 18px;
        @media ${screen.xxsmall} {
          width: 28px;
          height: 28px;
        }
      }
    }
  }
`;

const MemberModal = ({ isModal, setIsModal, member, setMember }) => {
  const name = member && member.name;
  const role = member && member.role;
  const description = member && member.description;
  const photo = member && member.photo;
  const qualifactions = member && member.qualifactions;
  const isOddItem = member && member.isOddItem;

  useEffect(() => {
    const userClick = (e) => {
      if (e.target.id === "member-modal") {
        setIsModal(false);
      }
    };
    window.addEventListener("click", userClick);

    return () => {
      window.removeEventListener("click", userClick);
    };
  }, []);

  const closeModal = () => {
    setIsModal(false);
  };

  return (
    <ScrollLocky isolation={false} enabled={isModal}>
      <AnimatePresence>
        {isModal && (
          <Wrapper
            initial="hidden"
            animate={isModal ? "show" : "hidden"}
            variants={container}
            id="member-modal"
            isOddItem={isOddItem}
            exit={{ opacity: 0 }}
          >
            <div className="container">
              <div className="flex">
                <div
                  className={
                    isOddItem
                      ? "img-wrapper img-wrapper--mobile-only img-wrapper--with-orange-bg"
                      : "img-wrapper img-wrapper--mobile-only img-wrapper--with-purple-bg"
                  }
                >
                  {photo && (
                    <div className="img-subwrapper">
                      <GatsbyImage image={photo} alt={`K9 SWiM ${name}`} />
                    </div>
                  )}
                  {isOddItem === 0 && (
                    <IconCircleWhiteLg className="icon-circle icon-circle--white-md" />
                  )}
                  {isOddItem === 0 && (
                    <IconCircleWhiteLg className="icon-circle icon-circle--white-lg" />
                  )}
                  {isOddItem === 0 && (
                    <IconCircleLightPurpleMd className="icon-circle icon-circle--light-purple" />
                  )}

                  {isOddItem !== 0 && (
                    <IconCircleWhiteLg className="icon-circle icon-circle--white" />
                  )}
                  {isOddItem !== 0 && (
                    <IconCircleLightPurpleMd className="icon-circle icon-circle--light-purple-lg" />
                  )}
                  {isOddItem !== 0 && (
                    <IconCircleLightPurpleLg className="icon-circle icon-circle--light-purple-md" />
                  )}
                </div>
                <motion.div className="col col--text" variants={item}>
                  <h3 className="name">{name}</h3>
                  <span className="role">{role}</span>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </motion.div>

                <motion.div className="col col--img" variants={item}>
                  <div
                    className={
                      isOddItem
                        ? "img-wrapper img-wrapper--with-orange-bg"
                        : "img-wrapper img-wrapper--with-purple-bg"
                    }
                  >
                    {photo && (
                      <div className="img-subwrapper">
                        <GatsbyImage image={photo} alt={`K9 SWiM ${name}`} />
                      </div>
                    )}
                    {isOddItem === 0 && (
                      <IconCircleWhiteMd className="icon-circle icon-circle--white-md" />
                    )}
                    {isOddItem === 0 && (
                      <IconCircleWhiteMd className="icon-circle icon-circle--white-lg" />
                    )}
                    {isOddItem === 0 && (
                      <IconCircleLightPurpleMd className="icon-circle icon-circle--light-purple" />
                    )}

                    {isOddItem !== 0 && (
                      <IconCircleWhiteMd className="icon-circle icon-circle--white" />
                    )}
                    {isOddItem !== 0 && (
                      <IconCircleLightPurpleMd className="icon-circle icon-circle--light-purple-lg" />
                    )}
                    {isOddItem !== 0 && (
                      <IconCircleLightPurpleMd className="icon-circle icon-circle--light-purple-md" />
                    )}
                  </div>
                  {qualifactions && (
                    <div className="subtext">
                      <h6>QUALIFICATIONS:</h6>
                      <p>{qualifactions}</p>
                    </div>
                  )}
                </motion.div>
              </div>

              <div className="icon-close" role="button" onClick={closeModal}>
                <motion.span whileTap={{ scale: 0.9 }}>
                  <IconClose />
                </motion.span>
              </div>
            </div>
          </Wrapper>
        )}
      </AnimatePresence>
    </ScrollLocky>
  );
};

export default MemberModal;
