import React, { useState } from "react";
import { graphql, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { motion } from "framer-motion";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  innerWidth,
  lightPurple,
  subFont,
  screen,
} from "../components/variables";
import {
  getFirstName,
  toSlug,
  formatDate,
  isOdd,
  reduceWords,
} from "../helpers";
import ButtonUnderlined from "../components/buttons/button-underlined";
import {
  IconCircleDarkPurpleMd,
  IconCircleLightPurpleMd,
  IconCircleLightPurpleLg,
  IconCircleCyanMd,
} from "../components/circles";
import IconArrowDown from "../images/svg/arrow-down.svg";
import IconArrowZigZag from "../images/svg/arrow-right-zigzag.svg";
import FaqRow from "../components/faq-row";
import ModalMember from "../components/member-modal";

const Wrapper = styled.div`
  min-height: 2000px;
  overflow: hidden;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .header {
    .heading {
      font-weight: 900;
      line-height: 1.22;
      max-width: 700px;
      font-size: 2rem;
      display: none;
      @media ${screen.xxsmall} {
        font-size: 3.8rem;
        display: block;
      }
      @media ${screen.small} {
        font-size: 5rem;
        line-height: 1.09;
        max-width: 850px;
      }

      .arrow {
        display: none;
        @media ${screen.xxsmall} {
          display: block;
          margin: 0 0 25px 0;
        }
        @media ${screen.xsmall} {
          margin: 0;
        }

        span {
          display: block;
          width: 25px;
          @media ${screen.xsmall} {
            width: 35px;
          }
        }
      }

      &--mobile-only {
        display: block;
        margin: 45px 0 0 0;
        @media ${screen.xxsmall} {
          display: none;
        }
      }
    }

    .featured-img {
      max-width: 780px;
      margin: 0 0 0 auto;
      width: 100%;
      position: relative;
      max-width: 880px;
      @media ${screen.small} {
        max-width: 820px;
      }
      @media ${screen.large} {
        max-width: 900px;
      }

      .icon-circle {
        &--light-purple-sm {
          display: none;
          @media ${screen.small} {
            display: block;
            bottom: 200px;
            left: -250px;
            width: 120px;
          }
          @media ${screen.large} {
            bottom: -110px;
            left: -430px;
            width: 150px;
          }
        }

        &--light-purple-lg {
          bottom: -21px;
          left: -52px;
          width: 82px;
          @media ${screen.xxsmall} {
            display: none;
          }
        }

        &--cyan {
          left: -105px;
          bottom: -105px;
          width: 200px;
          display: none;
          @media ${screen.small} {
            display: block;
            left: -90px;
            bottom: -90px;
            width: 190px;
          }
          @media ${screen.large} {
            left: -190px;
            bottom: -190px;
            width: 330px;
          }
        }
      }
    }

    .description {
      max-width: 575px;
      margin: 26px 0 0 0;
      @media ${screen.xxsmall} {
        margin: 55px 82px 0 0;
      }
      @media ${screen.xsmall} {
        margin: 55px 82px 0 auto;
      }

      p {
        margin: 0 0 21px 0;
        font-size: 1.02rem;
        @media ${screen.small} {
          font-size: 1.06rem;
        }
        &:last-child {
          margin: 0;
        }
      }
    }
  }

  .intro {
    margin: 60px 0 0 0;
    position: relative;
    overflow: hidden;
    @media ${screen.small} {
      margin: 21px 0 0 0px;
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      max-width: 410px;
      font-size: 1.56rem;
      @media ${screen.xxsmall} {
        font-size: 2.5rem;
        line-height: 1.19;
      }
      @media ${screen.small} {
        font-size: 3rem;
        max-width: 480px;
      }
    }

    .flex-description {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      margin: 21px 0 0 0;
      @media ${screen.xxsmall} {
        flex-direction: row;
        margin: 47px 82px 0 0;
      }

      .col {
        max-width: 575px;
        margin: 0 0 21px 0;
        @media ${screen.xxsmall} {
          margin: 0;
        }

        p {
          margin: 0 0 21px 0;
          font-size: 1.02rem;
          @media ${screen.small} {
            font-size: 1.06rem;
          }
          &:last-child {
            margin: 0;
          }
        }

        &--left {
          @media ${screen.xxsmall} {
            margin: 0 70px 0 0;
          }
        }
      }
    }

    .flex-featured-img {
      display: flex;
      flex-direction: column;
      margin: 21px 0 0 0;
      @media ${screen.xxsmall} {
        flex-direction: row;
        margin: 58px 0 0 0;
      }

      .col {
        height: 185px;
        @media ${screen.xxsmall} {
          height: 395px;
        }
        @media ${screen.small} {
          height: 495px;
        }

        &--left {
          background-image: ${(props) => `url(${props.intro_image_left})`};
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          margin: 0 0 21px 0;
          width: 100%;
          @media ${screen.xxsmall} {
            width: 65%;
            margin: 0 21px 0 0;
          }
        }

        &--right {
          background-image: ${(props) => `url(${props.intro_image_right})`};
          background-repeat: no-repeat;
          background-position: bottom;
          background-size: cover;
          width: 100%;
          height: 300px;
          @media ${screen.xxsmall} {
            width: 35%;
            height: 395px;
          }
          @media ${screen.small} {
            height: 495px;
          }
        }
      }
    }

    .icon-circle {
      width: 120px;
      top: 0;
      right: -12px;
      display: none;
      @media ${screen.xxsmall} {
        display: block;
      }
    }
  }

  .team {
    margin: 75px 0 32px 0;
    @media ${screen.xxsmall} {
      margin: 110px 0;
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      max-width: 225px;
      position: relative;
      font-size: 1.56rem;
      margin: 0 0 112px 0;
      @media ${screen.xxsmall} {
        font-size: 2.5rem;
        max-width: 382px;
        margin: 0;
      }
      @media ${screen.small} {
        font-size: 3rem;
        max-width: 435px;
        line-height: 1.19;
      }

      .pointer {
        position: absolute;
        top: 72px;
        right: 84px;
        @media ${screen.xxsmall} {
          top: 12px;
          right: 10px;
        }

        &__arrow {
          svg {
            width: 40px;
            transform: scaleY(-1) rotate(0deg);
            @media ${screen.xxsmall} {
              width: 49px;
              transform: scaleY(-1) rotate(22deg);
            }
          }
        }

        &__text {
          font-family: ${subFont};
          letter-spacing: 1px;
          font-size: 1.16rem;
          position: absolute;
          width: 150px;
          line-height: 1.15;
          top: 20px;
          right: -157px;
          font-weight: 400;
          @media ${screen.xxsmall} {
            font-size: 1.4rem;
            right: -182px;
            top: 12px;
            width: 170px;
          }
        }
      }
    }

    .team-list {
      display: flex;
      flex-wrap: wrap;
      margin: 35px 0 0 0;
      @media ${screen.xxsmall} {
        margin: 35px -13px 0 -13px;
      }
      @media ${screen.medium} {
        max-width: 1212px;
        margin: 35px 0 0 auto;
      }

      &__each {
        width: 100%;
        margin: 0 0 60px 0;
        @media ${screen.xxsmall} {
          width: calc(33.33% - 26px);
          margin: 0 13px 50px 13px;
        }
        @media ${screen.medium} {
          margin: 0 0 58px 26px;
        }

        .person-img {
          cursor: pointer;
        }

        .person-name {
          font-weight: 700;
          line-height: 1.4;
          margin: 16px 0 0 0;
          font-size: 1.25rem;
          @media ${screen.xxsmall} {
            font-size: 1.52rem;
            margin: 23px 0 0 0;
          }
          @media ${screen.small} {
            font-size: 1.55rem;
          }
        }

        .person-role {
          font-weight: 300;
          display: block;
          margin: 12px 0 18px 0;
          font-size: 1.03rem;
          @media ${screen.xxsmall} {
            margin: 12px 0 23px 0;
          }
          @media ${screen.small} {
            font-size: 1.07rem;
          }
        }
      }
    }
  }

  .blog {
    margin: 0 0 60px 0;
    @media ${screen.xxsmall} {
      margin: 110px 0;
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      font-size: 1.52rem;
      @media ${screen.xxsmall} {
        font-size: 2.5rem;
      }
      @media ${screen.small} {
        font-size: 3rem;
        line-height: 1.19;
      }
    }

    .blog-list {
      display: flex;
      flex-wrap: wrap;
      margin: 35px 0 0 0;
      @media ${screen.xxsmall} {
        margin: 35px -13px;
      }
      @media ${screen.medium} {
        max-width: 1212px;
        margin: 35px 0 0 auto;
      }

      &__each {
        display: flex;
        flex-direction: column;
        margin: 0 0 64px 0;
        @media ${screen.xxsmall} {
          margin: 0 13px;
          width: calc(33.33% - 26px);
        }
        @media ${screen.medium} {
          margin: 0 0 0 22px;
          width: calc(33.33% - 22px);
        }

        .featured-img {
          cursor: pointer;
        }

        .title {
          cursor: pointer;
          font-weight: 700;
          line-height: 1.4;
          margin: 23px 0 0 0;
          font-size: 1.25rem;
          @media ${screen.xxsmall} {
            font-size: 1.52rem;
          }
          @media ${screen.small} {
            font-size: 1.55rem;
          }
        }

        .date-tags {
          font-weight: 700;
          margin: 12px 0 13px 0;
          font-size: 1.02rem;
          @media ${screen.xxsmall} {
            font-size: 1.03rem;
          }
          @media ${screen.small} {
            font-size: 1.07rem;
          }

          .pipe-symbol {
            color: ${lightPurple};
            margin: 0 10px;
          }
        }

        .description {
          margin: 0 0 26px 0;
          padding: 0 18px 0 0;
          font-size: 1.02rem;
          @media ${screen.small} {
            font-size: 1.06rem;
          }
        }

        .link {
          margin: auto 0 0 0;
        }
      }
    }
  }
`;

const AboutPage = ({ data }) => {
  const [member, setMember] = useState(null);
  const [isModal, setIsModal] = useState(false);

  const {
    banner_heading,
    banner_description,
    banner_image,
    intro_heading,
    intro_description_col_one,
    intro_description_col_two,
    intro_image_left,
    intro_image_right,
    team_heading,
    member_list,
    title_tag,
    meta_description,
  } = data.content.data;

  const blogsByDate = data.blogs.edges
    .map((item) => {
      return {
        node: {
          // use edit published from prismic if provided
          display_date: item.node.data.edit_published_date
            ? formatDate(item.node.data.edit_published_date)
            : formatDate(item.node.first_publication_date),
          // use edit published from prismic if provided to sort blog list
          // reason to use this is to customize published date display for older blogs
          published_date: item.node.data.edit_published_date
            ? Number(item.node.data.edit_published_date.split("-").join(""))
            : Number(
                item.node.first_publication_date
                  .slice(0, 10)
                  .split("-")
                  .join("")
              ),
          data: item.node.data,
          id: item.node.id,
        },
      };
    })
    .sort((a, b) => a.node.published_date - b.node.published_date)
    .slice(0, 3);

  const openMemberModal = (
    e,
    name,
    role,
    description,
    photo,
    qualifactions,
    isOddItem
  ) => {
    e.preventDefault();

    setIsModal(!isModal);

    setMember({
      name,
      role,
      description,
      photo,
      qualifactions,
      isOddItem,
    });
  };

  const pageURL = `https://www.k9swim.com.au/about/`;

  return (
    <Layout noCirclesOnMobileFooter>
      <SEO
        title={title_tag || "About"}
        description={meta_description}
        image={banner_image.thumbnails.cropped.url}
        url={pageURL}
        webPageInfo={{
          name: title_tag || "About",
          url: pageURL,
          description: meta_description,
        }}
      />
      <Wrapper
        intro_image_left={intro_image_left.thumbnails.cropped.url}
        intro_image_right={intro_image_right.thumbnails.cropped.url}
      >
        <header className="header">
          <div className="inner-wrapper">
            <h1 className="heading">
              {banner_heading.text}
              <div className="arrow" role="presentation">
                <motion.span
                  animate={{ y: 7 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 1.1,
                  }}
                >
                  <IconArrowDown />
                </motion.span>
              </div>
            </h1>

            <figure className="featured-img">
              <GatsbyImage
                image={banner_image.thumbnails.cropped.gatsbyImageData}
                alt={banner_image.alt || "Featured"}
              />

              <IconCircleLightPurpleMd
                className="icon-circle icon-circle--light-purple-sm"
                withPointer
              />

              <IconCircleLightPurpleLg className="icon-circle icon-circle--light-purple-lg" />
              <IconCircleCyanMd className="icon-circle icon-circle--cyan" />
            </figure>

            <h1 className="heading heading--mobile-only">
              {banner_heading.text}
            </h1>

            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: banner_description.html }}
            />
          </div>
        </header>

        <section className="intro" data-sal="fade" data-sal-duration="700">
          <div className="inner-wrapper">
            <h2 className="heading">{intro_heading.text}</h2>
            <div className="flex-description">
              <div
                className="col col--left"
                dangerouslySetInnerHTML={{
                  __html: intro_description_col_one.html,
                }}
              />

              <div
                className="col"
                dangerouslySetInnerHTML={{
                  __html: intro_description_col_two.html,
                }}
              />
            </div>

            <div className="flex-featured-img">
              <div className="col col--left"></div>
              <div className="col col--right"></div>
            </div>
          </div>

          <IconCircleDarkPurpleMd className="icon-circle" />
        </section>

        <section className="team" data-sal="fade" data-sal-duration="700">
          <div className="inner-wrapper">
            <h2 className="heading">
              {team_heading.text}
              <span className="pointer" role="presentation">
                <span className="pointer__arrow">
                  <IconArrowZigZag />
                </span>
                <span className="pointer__text">
                  combined 30 years of experience!
                </span>
              </span>
            </h2>

            <ul className="team-list">
              {member_list.map((item, i) => (
                <li className="team-list__each" key={`${i}item.name.text`}>
                  <figure
                    className="person-img"
                    onClick={(e) => {
                      openMemberModal(
                        e,
                        item.name.text,
                        item.role,
                        item.description.html,
                        item.image.thumbnails.cropped.gatsbyImageData,
                        item.qualifications,
                        isOdd(i)
                      );
                    }}
                  >
                    {item.image.thumbnails.cropped.gatsbyImageData && (
                      <GatsbyImage
                        image={item.image.thumbnails.cropped.gatsbyImageData}
                        alt={item.image.alt || "Featured"}
                      />
                    )}
                  </figure>
                  <h3 className="person-name">{item.name.text}</h3>
                  <span className="person-role">{item.role}</span>
                  <ButtonUnderlined
                    onClick={(e) => {
                      openMemberModal(
                        e,
                        item.name.text,
                        item.role,
                        item.description.html,
                        item.image.thumbnails.cropped.gatsbyImageData,
                        item.qualifications,
                        isOdd(i)
                      );
                    }}
                    label={`MEET ${getFirstName(item.name.text)}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </section>

        <section className="blog" data-sal="fade" data-sal-duration="700">
          <div className="inner-wrapper">
            <h2 className="heading">What else is happening?</h2>
            <div className="blog-list">
              {blogsByDate.map((item) => (
                <article className="blog-list__each" key={item.node.id}>
                  <figure
                    className="featured-img"
                    onClick={() => {
                      navigate(`/blog/${toSlug(item.node.data.title.text)}/`);
                    }}
                  >
                    {item.node.data.featured_image.thumbnails.cropped
                      .gatsbyImageData && (
                      <GatsbyImage
                        image={
                          item.node.data.featured_image.thumbnails.cropped
                            .gatsbyImageData
                        }
                        alt={item.node.data.featured_image.alt || "Feautred"}
                      />
                    )}
                  </figure>
                  <h3
                    className="title"
                    onClick={() => {
                      navigate(`/blog/${toSlug(item.node.data.title.text)}/`);
                    }}
                  >
                    {item.node.data.title.text}
                  </h3>
                  <div className="date-tags">
                    <span>{item.node.display_date}</span>
                    {item.node.data.categories.map((item, i) => (
                      <span
                        key={
                          item.category.document
                            ? item.category.document.data.title.text
                            : i
                        }
                      >
                        <span className="pipe-symbol">|</span>
                        {item.category.document &&
                          item.category.document.data.title.text}
                      </span>
                    ))}
                  </div>
                  <p className="description">
                    {reduceWords(item.node.data.meta_description, 11)}...
                  </p>

                  <ButtonUnderlined
                    className="link"
                    label="LEARN MORE"
                    to={`/blog/${toSlug(item.node.data.title.text)}/`}
                  />
                </article>
              ))}
            </div>
          </div>
        </section>

        <FaqRow />
      </Wrapper>

      <ModalMember
        isModal={isModal}
        setIsModal={setIsModal}
        member={member}
        setMember={setMember}
      />
    </Layout>
  );
};

export default AboutPage;

export const dataQuery = graphql`
  {
    content: prismicAboutPage {
      data {
        banner_heading {
          text
        }
        banner_description {
          html
        }
        banner_image {
          alt
          thumbnails {
            cropped {
              url
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        intro_heading {
          text
        }
        intro_description_col_one {
          html
        }
        intro_description_col_two {
          html
        }
        intro_image_left {
          alt
          thumbnails {
            cropped {
              url
            }
          }
        }
        intro_image_right {
          alt
          thumbnails {
            cropped {
              url
            }
          }
        }
        team_heading {
          text
        }
        member_list {
          name {
            text
          }
          role
          description {
            html
          }
          image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          qualifications
        }
        title_tag
        meta_description
      }
    }
    blogs: allPrismicBlog(limit: 10) {
      edges {
        node {
          id
          first_publication_date
          data {
            title {
              text
            }
            meta_description
            categories {
              category {
                document {
                  ... on PrismicCategory {
                    data {
                      title {
                        text
                      }
                    }
                  }
                }
              }
            }
            edit_published_date
            featured_image {
              alt
              thumbnails {
                cropped {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    }
  }
`;
